import React from "react"
import style from "./Footer.module.scss"
import AnimatedLink from "../AnimatedLink/AnimatedLink"
import logo from "../../images/LIFT¯D_PP@1x.svg"
import fb from "../../images/fb-icon.svg"
import ig from "../../images/ig-icon.svg"
import banner_toscana from "../../images/banner_toscana.png"
import linkedin from "../../images/in-icon.svg"

const Footer = () => {
  return (
    <footer className={style.footer} id={"footer"}>
      <div className={[style.containerMedium, style.footerContainer].join(" ")}>
        <div className={style.column}>
          <div className={style.columnLogo}>
            <div className={style.logo}>
              <AnimatedLink
                to={"/"}
                exit={{ length: 0.5 }}
                entry={{ delay: 0.5 }}
              >
                <img src={logo} alt="Logo" />
              </AnimatedLink>
              <div className={style.copy}>
                <p>© 2019 LIFT-D Srl</p>
                <p>Tutti i diritti riservati</p>
              </div>
            </div>
          </div>
          <div className={style.columnSocial}>
            <div className={style.icons}>
              <a href="https://www.facebook.com/liftdesign/">
                <img src={fb} alt="facebook" />
              </a>
              <a href="/">
                <img src={ig} alt="instagram" />
              </a>
              <a href="https://www.linkedin.com/company/lift-d">
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
            <div className={style.termini}>
              <AnimatedLink
                to={"/"}
                exit={{ length: 0.5 }}
                entry={{ delay: 0.5 }}
              >
                <p>TERMINI E CONDIZIONI</p>
              </AnimatedLink>
              <AnimatedLink
                to={"/"}
                exit={{ length: 0.5 }}
                entry={{ delay: 0.5 }}
              >
                <p>PRIVACY POLICY</p>
              </AnimatedLink>
            </div>
          </div>
        </div>
        <div className={style.column1}>
          <div className={style.testo}>
            <p>
              Aiutiamo i leader, affiancando i team, nell’accelerare{" "}
              <span>l’innovazione strategica, tecnica e creativa.</span>
            </p>
          </div>
          <div className={style.columnLinks}>
            <div className={style.columnContatti}>
              <h3>Contattaci</h3>
              <a href="mailto:hello@lift-d.com">
                <p>hello@lift-d.com</p>
              </a>
              <p>+39 0575 250983</p>
              <AnimatedLink to={`/el4all`}>
                <img className={style.bandi} src={banner_toscana} />
              </AnimatedLink>
            </div>
            {/**<div className={style.columnScopri}>
                    <h3>Scopri</h3>
                      <AnimatedLink
                        to={"/portfolio"}
                        exit={{ length: 0.5 }}
                        entry={{ delay: 0.5 }}
                      >
                        <p>Portfolio</p>
                      </AnimatedLink>
                      <AnimatedLink
                        to={"/servizi"}
                        exit={{ length: 0.5 }}
                        entry={{ delay: 0.5 }}
                      >
                        <p>Servizi</p>
                      </AnimatedLink>
                  </div>*/}
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer

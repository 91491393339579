import { Power1, TimelineMax } from "gsap"

export const transitionEffect = ({ length }, transitionType) => {
  // verifica i valori passati
  if (typeof length === undefined) {
    length = 0.5
  }
  if (typeof transitionType === undefined) {
    //valori disponibili bottomToTop topToBottom imageExpand
    transitionType = "bottoToTop"
  }

  // elementi per la transizione
  const transitionCover = document.querySelector(".transitionCover")
  const layoutContents = document.querySelector(".transitionContents")

  // convert ms to s for gsap
  const seconds = length

  //animazione
  let animation = null

  if (typeof window !== undefined) {
    if (
      transitionType === "bottomToTop" &&
      transitionCover !== null &&
      layoutContents !== null
    ) {
      animation = new TimelineMax()
        .set(transitionCover, { y: "100%" })
        .to(transitionCover, seconds / 2, {
          y: "0%",
          ease: Power1.easeInOut,
        })
        .set(layoutContents, { opacity: 0 })
        .to(transitionCover, seconds / 2, {
          y: "-100%",
          ease: Power1.easeInOut,
        })
        .eventCallback("onComplete", function() {
          window.dispatchEvent(new Event("resize"))
        })
    }
  }

  if (layoutContents && transitionCover) {
    return { animation }
  } else {
    console.log("no animation possible")
  }
}

import React from "react"
import style from "./Header.module.scss"
import Menu from "../Menu/Menu"
import AnimatedLink from "../AnimatedLink/AnimatedLink"
import logo from "../../images/LIFT¯D_PP@1x.svg"

const Header = () => {
  return (
    <header className={style.header} id={"header"}>
      <div className={[style.containerMedium, style.headerContainer].join(" ")}>
        <div className={style.logo}>
          <AnimatedLink to={"/"} exit={{ length: 0.5 }} entry={{ delay: 0.5 }}>
            <img src={logo} alt="Logo" />
          </AnimatedLink>
        </div>
        <Menu />
      </div>
    </header>
  )
}
export default Header

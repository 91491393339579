import React, { useState } from "react"
import BackgroundImage from "gatsby-background-image"
import styles from "./HeaderImg.module.scss"
import Img from "gatsby-image"

const HeaderImg = ({
  img,
  tipoheader,
  children,
  coloreBackground,
  imageArray,
}) => {
  let classHeader = []

  switch (tipoheader) {
    case "testataFullScreen":
      classHeader.push(styles.headerImage)
      break
    case "testataStandard":
      classHeader.push(styles.headerImagewithTitle)
      break
    case "testataStandardPullUp":
      classHeader.push(styles.headerImagewithTitlePU)
      break
    case "testataMultipleImages":
      if (imageArray !== null) {
        classHeader.push(styles.headerMultipleImages)
        classHeader.push("customScrollAnimation")
        classHeader.push("customScrollChildrenAppear")
      } else {
        classHeader.push(styles.headerImagewithTitle)
      }
      break
    default:
      classHeader.push(styles.headerImagewithTitle)
      break
  }

  const [parallasse, setParallasse] = useState({ xy: [0, 0] })

  const transformParallax = (x, y, value, value2) => {
    return `translateX(${x / value}px) rotateX(${y * value2}deg) rotateY(${x *
      -value2}deg)`
  }
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

  const multipleimagesRender =
    imageArray !== null
      ? imageArray.map(function(item, index) {
          return (
            <div
              className={styles.childrenImgs}
              key={index}
              style={{
                transform: transformParallax(
                  parallasse.xy[0],
                  parallasse.xy[1],
                  index * 50 + 50,
                  index * -0.01 - 0.005
                ),
              }}
            >
              <div className={"scrollChildren slowScrollChildren"}>
                <Img
                  fluid={item.immagine_testata.localFile.childImageSharp.fluid}
                  alt={item.immagine_testata.alt_text}
                />
              </div>
            </div>
          )
        })
      : null

  function layout() {
    switch (img) {
      case null:
        return (
          <div
            className={classHeader.join(" ")}
            style={{
              backgroundColor: coloreBackground,
            }}
            onMouseMove={({ clientX: x, clientY: y }) =>
              setParallasse({ xy: calc(x, y) })
            }
          >
            {multipleimagesRender}
            {children}
          </div>
        )
      default:
        return (
          <BackgroundImage
            fluid={img.localFile.childImageSharp.fluid}
            className={classHeader.join(" ")}
            backgroundColor={coloreBackground}
          >
            {children}
          </BackgroundImage>
        )
    }
  }
  return <div>{layout()}</div>
}

HeaderImg.defaultProps = {
  tipoheader: "testataStandard",
  img: null,
  coloreBackground: "#FEF8EA",
  imageArray: null,
}

export default HeaderImg

import React, { useEffect, useState } from "react"
import {
  TransitionPortal,
  TransitionState,
} from "gatsby-plugin-transition-link"
import "../../animations/_customScroll.scss"

const TransitionBehavior = ({ children }) => {
  const [pageStateLoad, setPageStateLoad] = useState(null)

  useEffect(() => {
    let keypressListener = function(e) {}
    let focusListener = function(e) {}
    let autofillerEvent = function(e) {}
    function getPosition(element) {
      var yPosition = 0

      while (element) {
        yPosition += element.offsetTop - element.scrollTop + element.clientTop
        element = element.offsetParent
      }

      return yPosition
    }
    if (typeof window !== undefined) {
      const CustomScroll = require("../../animations/customScroll").default
      const section = document.querySelector(".vs-section")
      const targetAnimation = document.querySelectorAll(
        ".customScrollAnimation"
      )
      var autofillerFields

      /* gestione del tab */
      var focusableEls = document.querySelectorAll(
        "a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])"
      )
      autofillerFields = document.querySelectorAll(
        "textarea:not([disabled]), input:not([disabled])"
      )
      var currentFocus = 0
      keypressListener = function(e) {
        if (e.key === "Tab" || e.keyCode === 9) {
          e.preventDefault()
          e.stopPropagation()

          if (e.shiftKey) {
            currentFocus--
            if (currentFocus < 0) {
              currentFocus = focusableEls.length - 1
            }
          } else {
            currentFocus++
            if (currentFocus >= focusableEls.length) {
              currentFocus = 0
            }
          }

          smooth.instantScrollTo(0)
          smooth.instantScrollTo(
            getPosition(focusableEls[currentFocus]),
            focusableEls[currentFocus]
          )
          return false
        }

        if (e.key === null || typeof e.key === "undefined") {
          smooth.instantScrollTo(0)
          smooth.instantScrollTo(
            getPosition(focusableEls[currentFocus]),
            focusableEls[currentFocus]
          )
          return false
        }
      }

      autofillerEvent = function(e) {
        console.log(e)
        e.preventDefault()

        smooth.instantScrollTo(
          getPosition(focusableEls[currentFocus]),
          focusableEls[currentFocus]
        )
        return false
      }

      focusListener = function(e) {
        console.log(e)
        if (findinFocus(focusableEls, e.target) !== false) {
          currentFocus = findinFocus(focusableEls, e.target)
        }
      }

      function findinFocus(haystack, needle) {
        let found = false
        let j = 0
        while (j < haystack.length) {
          if (haystack[j] === needle) {
            found = j
          }
          j++
        }
        return found
      }

      var smooth = new CustomScroll({
        extends: true,
        native: false,
        preload: true,
        section: section,
        animated: targetAnimation,
        ease: 0.1,
      })

      smooth.init()

      window.dispatchEvent(new Event("resize"))
      document.addEventListener("keydown", keypressListener)
      document.addEventListener("focusin", focusListener)

      let j = 0
      while (j < autofillerFields.length) {
        autofillerFields[j].addEventListener("change", autofillerEvent)
        j++
      }
    }

    return function cleanup() {
      if (typeof window !== undefined) {
        //console.log("destroy");
        smooth.destroy()
        document.removeEventListener("keydown", keypressListener)
        document.removeEventListener("focusin", focusListener)
        let j = 0
        while (j < autofillerFields.length) {
          autofillerFields[j].removeEventListener("change", autofillerEvent)
          j++
        }
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== undefined) {
      //console.log("resize");
      window.dispatchEvent(new Event("resize"))
    }
  }, [pageStateLoad])

  return (
    <TransitionState>
      {({ transitionStatus }) => {
        setPageStateLoad(transitionStatus)
        return (
          <div className={"vs-section"} tabIndex={-1}>
            {children}
            <TransitionPortal>
              <div className={"transitionCover"} />
            </TransitionPortal>
          </div>
        )
      }}
    </TransitionState>
  )
}

export default TransitionBehavior

import React from "react"
import styles from "./Heading.module.scss"

const Heading = ({ title, subtitle, ish1, alignment, children }) => {
  var styleheading = [styles.heading]
  if (alignment === "center") {
    styleheading.push(styles.centered)
  } else if (alignment === "right") {
    styleheading.push(styles.righted)
  } else {
    styleheading.push(styles.lefted)
  }

  return (
    <div className={styleheading.join(" ")}>
      {ish1 ? (
        <h1 className={"scrollChildren"}>{title}</h1>
      ) : (
        <h3 className={"scrollChildren"}>{title}</h3>
      )}
      <p className={"scrollChildren"}>{subtitle}</p>
      {children}
    </div>
  )
}

Heading.defaultProps = {
  ish1: true,
  alignment: "center",
}

export default Heading

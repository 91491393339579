import { StaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import AnimatedLink from "../AnimatedLink/AnimatedLink"
import styles from "./Menu.module.scss"

const Menu = () => {
  const [menuState, setMenuState] = useState(false)
  const toggle = () => setMenuState(!menuState)
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressWpApiMenusMenusItems {
            items {
              title
              object_slug
            }
          }
        }
      `}
      render={data => (
        <header>
          <button
            className={
              menuState
                ? [styles.menuToggler, styles.menuTogglerActive].join(" ")
                : styles.menuToggler
            }
            onClick={toggle}
          >
            {" "}
            <span></span> <span></span>
          </button>
          <div
            className={
              menuState ? [styles.menu, styles.openMenu].join(" ") : styles.menu
            }
          >
            <ul>
              {data.wordpressWpApiMenusMenusItems.items.map(item => (
                <li key={item.object_slug}>
                  <AnimatedLink to={`/${item.object_slug}`}>
                    {item.title}
                  </AnimatedLink>
                </li>
              ))}
            </ul>
          </div>
        </header>
      )}
    />
  )
}

export default Menu

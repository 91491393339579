import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { transitionEffect } from "../../animations/animation"

const AnimatedLink = ({ to, children, transitionType }) => {
  return (
    <TransitionLink
      to={to}
      exit={{
        length: 0.8,
        trigger: ({ exit }) => transitionEffect(exit, transitionType),
      }}
      entry={{ delay: 0.4 }}
      activeStyle={{ pointerEvents: "none" }}
    >
      {children}
    </TransitionLink>
  )
}

AnimatedLink.defaultProps = {
  transitionType: "bottomToTop",
}

export default AnimatedLink

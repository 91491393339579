import Smooth from "smooth-scrolling"
class CustomScroll extends Smooth {
  constructor(opt) {
    super(opt)
    this.dom.animated = opt.animated
    this.runningjjs = false
  }

  init() {
    super.init()
    this.runningjjs = true
    this.dom.animated.forEach(function(element, index, array) {
      element
        .querySelectorAll(".scrollChildren")
        .forEach(function(element, index) {
          element.style.transitionDelay = 300 * index + "ms"
        })
      element
        .querySelectorAll(".slowScrollChildren")
        .forEach(function(element, index) {
          element.style.transitionDelay = 1000 * index + "ms"
        })
    })
  }

  run() {
    const current = Math.round(Math.abs(this.vars.current))

    this.dom.animated.forEach(function(element, index, array) {
      if (current + (window.innerHeight / 4) * 3 > element.offsetTop) {
        element.classList.add("customScrollFired")
        if (typeof window !== undefined) {
          window.dispatchEvent(new Event("resize"))
        }
      }
    })
    super.run()
    this.dom.section.style[this.prefix] = this.getTransform(
      -this.vars.current.toFixed(2)
    )
  }

  instantScrollTo(offset, elementToFocus = null) {
    this.clampTarget()
    this.vars.current = this.vars.target

    this.vars.target = offset
    this.clampTarget()
    this.vars.current = this.vars.target
    if (typeof window !== undefined) {
      window.dispatchEvent(new Event("resize"))
    }
    if (elementToFocus !== null) {
      setTimeout(function() {
        elementToFocus.focus()
      }, 10)
    }
  }

  resize() {
    this.vars.bounding =
      this.dom.section.getBoundingClientRect().height - this.vars.height
    super.resize()
  }
}
export default CustomScroll

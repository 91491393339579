import React from "react"
import HeaderImg from "../components/HeaderImg/HeaderImg"
import Heading from "../components/Heading/Heading"
import Header from "../components/Header/Header"
import SEO from "../components/seo"
import Footer from "../components/Footer/Footer"
import TransitionBehavior from "../components/TransitionBehavior/TransitionBehavior"
import parse from "html-react-parser"
import style from "./templateBlank.module.scss"
import banner_toscana from "../images/banner_toscana.png"

const TemplateBlank = props => {
  const { pageContext } = props
  const { meta, titolo, content } = pageContext
  const { transitionStatus } = props
  let transitionClass = "transitionContents " + transitionStatus

  return (
    <TransitionBehavior>
      <Header />
      <SEO meta={meta} />
      <div className={transitionClass}>
        <HeaderImg>
          <Heading title={titolo} ish1={true} align={"center"}></Heading>
        </HeaderImg>
        <div className={[style.containerMedium, style.content].join(" ")}>
          {parse(content)}
        </div>
        <Footer />
      </div>
    </TransitionBehavior>
  )
}

export default TemplateBlank
